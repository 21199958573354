function addCartCallbacks() {
    miniShop2.Callbacks.add('Cart.add.response.success', 'update_pages', handleCartChange);
    miniShop2.Callbacks.add('Cart.add.response.error', 'update_pages', handleCartChange);
    miniShop2.Callbacks.add('Cart.change.response.success', 'update_pages', handleCartChange);
    miniShop2.Callbacks.add('Cart.change.response.error', 'update_pages', handleCartChange);


    miniShop2.Callbacks.add('Cart.change.before', 'update_pages', function(r) {
        var $rows = $('.js-cart-table tbody tr');
        $rows.each(function() {
            var $input = $('input[name="count"]', this);
            if ($input.length && +$input.data('min') > +$input.val()) {
                $input.val($input.data('min'));
            }
        });
    });
}

function handleCartChange(r) {
    if (r.data.total_cost > 0) {
        $('#miniCart').addClass('app-cart-snippet--no-empty');
        $('#mobileMiniCart').addClass('app-cart-snippet--no-empty').addClass('app-cart-snippet--no-empty--tabl');
    }
    var $table = $('.js-cart-table');
    if (!$table.length) {
        return;
    }

    for (var key in r.data.items) {
        var $row = $('#' + key);
        var itemData = r.data.items[key];
        $row.find('input[name="count"]').val(itemData.count);
        $row.find('.js-product-price').text(miniShop2.Utils.formatPrice(itemData.price));
        $row.find('.js-product-total').text(miniShop2.Utils.formatPrice(itemData.price * itemData.count));
    }
}

export default {
    addCartCallbacks,
}