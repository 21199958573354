function bindCounts() {
    var $counts = $('.app-counts');
    $counts.find('.app-counts__up').on('click', function() {
        var $input = $(this).parent().find('.app-counts__input');
        $input.val(+$input.val() + 1);
        $input.trigger('change');
    });
    $counts.find('.app-counts__down').on('click', function() {
        var $input = $(this).parent().find('.app-counts__input');
        if ($input.val() > 1) {
            $input.val(+$input.val() - 1);
            $input.trigger('change');
        }
    })
}

export default {
    bindCounts,
}