function bindBurger() {
	$('.burger').on('click',function(){
		if (!$(this).hasClass('showMenu')) {
			$(this).addClass('showMenu');
			$(this).parent().find('.media-menu').animate({
				'left': 0
			}, 400);
			$(this).parent().find('.bg-media-menu').css({'display':'block'}).animate({
				'opacity': 1
			}, 400);
		}else{
			$(this).removeClass('showMenu');
			$(this).parent().find('.media-menu').animate({
				'left': '0'
			}, 400);
			$(this).parent().find('.bg-media-menu').animate({'opacity': '0'}, 400).css({'display':'none'});
		}
	});
	$('.box-media-menu').on('click','.bg-media-menu, .close-burger',function(){
			$('.burger').removeClass('showMenu');
			$('.media-menu').animate({
				'left': '0'
			}, 400);
			$('.bg-media-menu').animate({'opacity': '0'}, 400).css({'display':'none'});
	});
}

function init() {
	bindBurger();
}

export default {
	init,
}
