/*
Пример подключения модулей:
test.js:
export default function() {}
main.js:
import test from './test';
test();
*/
import Header from './components/header';
import Utils from './components/utils';
import Cart from './components/cart';

$(function() {
	Header.init();
	Utils.bindCounts();
	Cart.addCartCallbacks();
});